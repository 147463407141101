import * as globalOnScan from 'onscan.js/onscan';
import CameraScanner from './camera_scanner';

class Scan {
  currentCamera = new CameraScanner();

  constructor() {
    this.init();
    this.currentCamera.init();
  }

  init() {
    const $this = this;
    try {
      globalOnScan.detachFrom(document);
    } catch(e) {
      console.log(e)
    }
    globalOnScan.attachTo(document, {
      minLength: 3,
      ignoreIfFocusOn: ['input', 'textarea'],
      onScan: function(value, iQty) {
        $this.sendEvent(value);
      },
      keyCodeMapper: function(oEvent) {
        if (oEvent.which == 189) {
          return '-';
        }
        if (oEvent.code == 'Period') {
          return '.';
        }
        return globalOnScan.decodeKeyEvent(oEvent);
      },
      onScanError: function(oDebug){
        console.log(oDebug)
      }
    });
  }

  cameraScanner() {
    return this.currentCamera;
  }

  sendEvent(value) {
    const event = new Event('scanning');
    event.value = value;
    window.dispatchEvent(event);
  }
}

window.scan = new Scan();

export default window.scan;
